// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Picture from "../../../styleguide/forms/Picture/Picture.res.js";
import * as $$Location from "../../../models/Location.res.js";
import * as GoogleMap from "../../../bindings/GoogleMap.res.js";
import * as IconClose from "../../../styleguide/icons/IconClose.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as GoogleMapStyles from "../../../styleguide/styles/GoogleMapStyles.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationMapScss from "./LocationMap.scss";
import * as GoogleMapWithBounds from "../../../styleguide/components/GoogleMap/GoogleMapWithBounds.res.js";
import * as Global from "Client/common/global";
import * as Api from "@react-google-maps/api";

var css = LocationMapScss;

function reducer(state, action) {
  if (typeof action === "object") {
    return {
            showInfoBox: state.showInfoBox,
            status: state.status,
            clickedPoint: action._0
          };
  }
  switch (action) {
    case "EnableInfoBox" :
        return {
                showInfoBox: true,
                status: state.status,
                clickedPoint: state.clickedPoint
              };
    case "FailSubmission" :
        return {
                showInfoBox: state.showInfoBox,
                status: "Viewing",
                clickedPoint: state.clickedPoint
              };
    case "CloseInfoBox" :
        return {
                showInfoBox: state.showInfoBox,
                status: state.status,
                clickedPoint: undefined
              };
    
  }
}

function propsFromLocation($$location) {
  return {
          id: $$location.id,
          latitude: $$location.latitude,
          longitude: $$location.longitude,
          imageUrl: $$location.imageUrl,
          name: $$location.name,
          fullAddress: $$location.fullAddress,
          url: $$location.url
        };
}

function propsFromLocationDetail($$location) {
  return {
          id: $$location.id,
          latitude: $$location.latitude,
          longitude: $$location.longitude,
          imageUrl: $$location.imageUrl,
          name: $$location.name,
          fullAddress: $$location.fullAddress,
          url: $$location.url
        };
}

function LocationMap(props) {
  var serverSide = props.serverSide;
  var __markers = props.markers;
  var $$location = props.location;
  var markers = __markers !== undefined ? __markers : [];
  var match = React.useReducer(reducer, React.useMemo((function () {
              return {
                      showInfoBox: false,
                      status: "Viewing",
                      clickedPoint: undefined
                    };
            }), []));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (markers.length === 0) {
            dispatch("EnableInfoBox");
          }
          
        }), []);
  var point = state.clickedPoint;
  var match$1 = state.showInfoBox;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Api.LoadScript, {
                    googleMapsApiKey: Global.GoogleMapAPI,
                    libraries: Global.GoogleMapLibraries,
                    children: JsxRuntime.jsxs(GoogleMapWithBounds.make, {
                          mapContainerClassName: css.mapElement,
                          zoom: markers.length !== 0 ? undefined : 8,
                          center: $$location !== undefined ? ({
                                lat: $$location.latitude,
                                lng: $$location.longitude
                              }) : undefined,
                          style: GoogleMapStyles.grey,
                          options: {
                            mapTypeControl: false,
                            zoomControl: true,
                            zoomControlOptions: {
                              position: 5
                            },
                            streetViewControl: false,
                            fullscreenControl: false
                          },
                          bounds: markers.length !== 0 ? Belt_Array.map(markers, (function (marker) {
                                    return {
                                            lat: marker.latitude,
                                            lng: marker.longitude
                                          };
                                  })) : [],
                          children: [
                            markers.length !== 0 ? JsxRuntime.jsx(GoogleMap.MarkerClusterer.make, {
                                    averageCenter: true,
                                    enableRetinaIcons: true,
                                    gridSize: 60,
                                    minimumClusterSize: 3,
                                    styles: GoogleMap.MarkerClusterer.Style.$$default,
                                    children: (function (clusterer) {
                                        return Belt_Array.map(markers, (function (marker) {
                                                      return JsxRuntime.jsx(GoogleMap.Marker.make, {
                                                                  position: {
                                                                    lat: marker.latitude,
                                                                    lng: marker.longitude
                                                                  },
                                                                  onClick: (function () {
                                                                      dispatch({
                                                                            TAG: "ShowInfoBoxForPoint",
                                                                            _0: marker
                                                                          });
                                                                    }),
                                                                  icon: serverSide ? undefined : GoogleMap.Marker.Icon.$$default,
                                                                  clusterer: Caml_option.some(clusterer)
                                                                }, ID.toString(marker.id));
                                                    }));
                                      })
                                  }) : null,
                            point !== undefined ? JsxRuntime.jsx(GoogleMap.InfoBox.make, {
                                    position: {
                                      lat: point.latitude,
                                      lng: point.longitude
                                    },
                                    contentWidth: 240.0,
                                    contentHeight: 250.0,
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Button.make, {
                                                  size: "MD",
                                                  color: "Primary",
                                                  className: css.closeBtn,
                                                  onClick: (function (param) {
                                                      dispatch("CloseInfoBox");
                                                    }),
                                                  children: JsxRuntime.jsx(IconClose.make, {
                                                        title: "Close",
                                                        size: "MD",
                                                        color: "White"
                                                      })
                                                }),
                                            JsxRuntime.jsx(A.make, {
                                                  href: point.url,
                                                  targetBlank: true,
                                                  children: JsxRuntime.jsxs("figure", {
                                                        children: [
                                                          JsxRuntime.jsx(Picture.make, {
                                                                src: point.imageUrl,
                                                                large: [
                                                                  240,
                                                                  160
                                                                ],
                                                                fallbackSrc: $$Location.imageNotFoundUrl
                                                              }),
                                                          JsxRuntime.jsxs("figcaption", {
                                                                children: [
                                                                  JsxRuntime.jsx("header", {
                                                                        children: point.name,
                                                                        title: point.name
                                                                      }),
                                                                  JsxRuntime.jsx("address", {
                                                                        children: point.fullAddress
                                                                      })
                                                                ]
                                                              })
                                                        ]
                                                      })
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  className: css.arrow
                                                })
                                          ],
                                          className: css.infoWindow
                                        })
                                  }) : null,
                            match$1 && $$location !== undefined ? JsxRuntime.jsx(GoogleMap.InfoBox.make, {
                                    position: {
                                      lat: $$location.latitude,
                                      lng: $$location.longitude
                                    },
                                    contentWidth: 240.0,
                                    contentHeight: 250.0,
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(A.make, {
                                                  href: $$location.url,
                                                  targetBlank: true,
                                                  children: JsxRuntime.jsxs("figure", {
                                                        children: [
                                                          JsxRuntime.jsx(Picture.make, {
                                                                src: $$location.imageUrl,
                                                                large: [
                                                                  240,
                                                                  160
                                                                ],
                                                                fallbackSrc: $$Location.imageNotFoundUrl
                                                              }),
                                                          JsxRuntime.jsxs("figcaption", {
                                                                children: [
                                                                  JsxRuntime.jsx("header", {
                                                                        children: $$location.name,
                                                                        title: $$location.name
                                                                      }),
                                                                  JsxRuntime.jsx("address", {
                                                                        children: $$location.fullAddress
                                                                      })
                                                                ]
                                                              })
                                                        ]
                                                      })
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  className: css.arrow
                                                })
                                          ],
                                          className: css.infoWindow
                                        })
                                  }) : null
                          ]
                        })
                  })
            });
}

var make = LocationMap;

var $$default = LocationMap;

export {
  css ,
  reducer ,
  propsFromLocation ,
  propsFromLocationDetail ,
  make ,
  $$default as default,
}
/* css Not a pure module */
